const bootstrap = require('../../node_modules/bootstrap')
import sal from '../js/sal.js'

sal({
  threshold: 0.7,
})

/*Map*/
let mymap = L.map('mapid', { scrollWheelZoom: false }).setView([46.71109, 1.7191036], 5)

if (
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/iPad/i) ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Windows Phone/i)
) {
  document.querySelector('#mapid')
  mymap.invalidateSize()
  mymap.setView([46.71109, 1.7191036], 5)
} else {
  document.querySelector('#mapid')
  mymap.invalidateSize()
  mymap.setView([46.71109, 1.7191036], 6)
}

L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
  attribution: '© OpenStreetMap contributors',
  minZoom: 1,
  maxZoom: 18,
  id: 'mapbox/streets-v11',
  tileSize: 512,
  zoomOffset: -1,
  scrollwheel: false
}).addTo(mymap)

let cookieIcon = L.icon({
  iconUrl: 'https://img.icons8.com/plasticine/100/000000/cookie.png',
  iconSize: [40, 40],
})

function infosMap() {
  fetch('https://test.cookie.menu/api/restaurants/coordinates/', {
    methods: 'GET',
  })
    .then((response) => response.json())
    .then((json) => {
      const coordinatesMap = json.map((item) => {
        return {
          id: item.id,
          name: item.name,
          coordinates: item.coordinates ? item.coordinates.reverse() : [],
        }
      })
      let marker

      for (let i = 0; i < coordinatesMap.length; i++) {
        if (coordinatesMap[i].coordinates.length > 1) {
          let link = 'https://app.cookie.menu/restaurants/'
          marker = new L.marker(
            [
              coordinatesMap[i].coordinates[0],
              coordinatesMap[i].coordinates[1],
            ],
            { icon: cookieIcon }
          )
            .addTo(mymap)
            .on('click', function (e) {
              window.open(link + coordinatesMap[i].id)
            })
            .bindTooltip(coordinatesMap[i].name)
        }
      }
    })
}

infosMap()
